import appConfig from "@/config";
import {getCsrfToken, getStorage} from "@/utility/helper";
import {setLogin} from "@/utility/authHelpers";
import {Alpine} from "@/alpineJS";
import {
  actionCallbackAfterAuth,
  alertSignIn,
  authSetLayoutVerifyEmail,
  showModalSignIn
} from "@/pages/auth/helpers";
import {AuthType} from "@/pages/auth/store-data";

const dataResponse = {
  show: false,
  success: false,
  message: '',
  data: {} as any,
  statusCode: 200,
  is_new_password: false,
}

/**
 * page is static, modal
 * */
export default (
  email = '' as string | unknown,
  page = 'static' as string | unknown) =>
  ({
    loading: false,
    email: email as string,
    password: '',
    new_password: '',
    dataResponse: dataResponse,
    async submitSignIn() {
      if (dataResponse?.data?.is_new_password) {
        this.new_password = this.password;
      }
      if (!this.password.length) {
        return;
      }
      this.dataResponse.show = false;
      let res = await fetch(`${appConfig.apiEndPoint}/sign-in`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCsrfToken(),
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
          new_password: this.new_password,
        })
      })
      // set data response text
      let data = await res.json()
      this.dataResponse = data;
      this.dataResponse.show = true;
      this.loading = false;
      // set auth token
      actionSignIn(data, page);
    },
    gotoSignInLayout() {
      let dataStore = Alpine.store('auth') as AuthType;
      dataStore.layout = 'sign-in';
      this.dataResponse = dataResponse; // Reset data response
    },
    isLoading() {
      if (this.password.length > 0 && this.email.length > 0) {
        this.loading = true
      }
    }
  });


const actionSignIn = (data: any, page = 'static' as string | unknown) => {
  if (data?.success && data?.auth_token) {
    setLogin(data.auth_token, data?.email);
    if (page === 'modal') {
      // after sign-in check auth-callback for doing action after sign-in
      actionCallbackAfterAuth();
      if (data.email_verified_at === false) {
        authSetLayoutVerifyEmail();
      } else {
        showModalSignIn(false);
      }
      // show Toast sign-in success
      alertSignIn(data);
    } else {
      window.location.href = data.redirect_url;
    }
  }
}

