import {initInterPhone} from "@/modules/interPhone/inter-phone";
import '../../../../css/pages/auth.css'
import {fetchRegister, setAuthTokenAfterRegister, setEmail} from "@/modules/form/sign-up/helpers";
import {i18nMain} from "@/modules/translations/i18n.main";
import {facebookCompleteRegistration} from "@/modules/tracking/tracking.index";

let itiPhone = initInterPhone("phone-register");

interface attribute {
  first_name: string
  last_name: string
  email: string
  phone: string
  password: string
  role: string
  accept_agents: boolean,
  display_name?: string
}

const defaultAttribute = <attribute>{
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
  role: "",
  accept_agents: false,
  display_name: ""
}

export default (
  page = 'static' as string | unknown
) => ({
  attribute: <attribute>defaultAttribute,
  errors: <attribute>{},
  loading: false,
  message: '',
  async submit() {
    this.errors = <attribute>{};
    this.message = "";
    // validate phone number
    let isValid = itiPhone?.isValidNumber();
    if (!isValid) {
      this.message = i18nMain.t('errors.data-invalid');
      this.errors.phone = i18nMain.t('errors.data-invalid-attribute', {
        attr: i18nMain.t('attributes.phone-number')
      });
      return;
    }
    this.loading = true
    let phone = itiPhone?.getNumber();
    let role = document.querySelector('input[name="selectedRole"]:checked') as HTMLInputElement;
    let roleName = document.getElementById('role-name') as HTMLInputElement;
    this.attribute.role = role?.value;
    const data = await fetchRegister({
      first_name: this.attribute.first_name,
      last_name: this.attribute.last_name,
      display_name: this.attribute.display_name,
      password: this.attribute.password,
      email: this.attribute.email,
      role: this.attribute.role,
      phone: phone,
      accept_agents: this.attribute.accept_agents,
      auto_sign_in: true
    })
    this.loading = false;
    if (data?.success) {
      // Set value input email sign form
      setEmail(this.attribute.email);
      // tracking facebook
      facebookCompleteRegistration();
      // Set alert message
      setAuthTokenAfterRegister(data, roleName?.value, page);
      // Reset form
      this.resetAttribute();
    } else {
      this.message = data?.message
      this.errors = data?.errors
    }
  },
  resetForm() {
    this.resetAttribute();
    this.errors = <attribute>{}
    this.message = '';
  },
  resetAttribute() {
    this.attribute = <attribute>{
      first_name: "",
      last_name: "",
      display_name: "",
      email: "",
      phone: "",
      password: "",
      role: "",
      accept_agents: false
    }
  }
});

