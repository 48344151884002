import {Alpine} from "@/alpineJS";
import appConfig from "@/config";
import {Toast} from "@/modules/sweetAlert/alert";
import {setLogin} from "@/utility/authHelpers";
import {registerForm, signInForm, verifyEmail} from "@/services";
import {ModalMinimalType} from "@/modules/modals/modal";
import {i18nMain} from "@/modules/translations/i18n.main";
import {AuthType, initStoreAuth} from "@/pages/auth/store-data";
import {getCsrfToken, getStorage, setStorage} from "@/utility/helper";

/** Store data and  */
export const initAuthFn = () => {
  Alpine.data('registerForm', registerForm)
  Alpine.data('signInForm', signInForm)
  Alpine.data('verifyEmail', verifyEmail)
  initStoreAuth();
  eventListenerSocialAuth();
}

/**
 * Social Sign-in
 * */
export const eventListenerSocialAuth = () => {
  /** on click google log-in */
  document.getElementById("button-login-google")
    ?.addEventListener('click', function (e) {
      e.preventDefault();
      let {url, opts} = urlPopupSocialAuth('google');
      window.open(url, 'oauth', opts);
    });

  /** on click facebook log-in */
  document.getElementById("button-login-facebook")
    ?.addEventListener('click', function (e) {
      e.preventDefault();
      let {url, opts} = urlPopupSocialAuth('facebook');
      window.open(url, 'oauth', opts);
    });

  /** event when new window sign-in callback (social log-in) */
  window.addEventListener('message', function (event) {
    let res = event.data;
    if (res.action === 'closeModalSignIn') {
      document.getElementById('log-in-form')
        ?.classList.add('hidden');
      // get mode log-in from element - modal or page
      const mode = document.getElementById('social-auto-sign-in') as HTMLInputElement;

      if (res?.auth_token) {
        setLogin(res?.auth_token, res?.email);
        if (mode?.value === 'modal') {
          Toast.fire({icon: 'success', title: res?.message});
        } else {
          // check verify email
          window.location.href = res.redirect_url;
        }
      } else {
        Toast.fire({icon: 'error', title: res?.message})
      }
    }
  }, false);
}

const urlPopupSocialAuth = (platform) => {
  let width = 600,
    height = 600,
    left = (window.innerWidth - width) / 2,
    top = (window.innerHeight - height) / 2;

  // Replace this with your actual Laravel Socialite login URL.
  let url = `${appConfig.domain}/auth/${platform}/redirect`;

  let opts = 'status=1' +
    ',width=' + width +
    ',height=' + height +
    ',top=' + top +
    ',left=' + left;
  return {url, opts};
}

/**
 * Set modal auth show verify-email section
 * always set layout default
* */
export const authSetLayoutVerifyEmail = () => {
  let dataStore = Alpine.store('auth') as AuthType;
  dataStore.layout = 'verify-email';
  showVerifyEmailOriginalSection(true);
}

export const showModalSignIn = (isShow = true, isShowClose = true) => {
  let contactModal = Alpine.store('modalMinimal') as ModalMinimalType;
  contactModal.setToggle('log-in-form', isShow, isShowClose);
}

export const setInnerTextEmailVerify = () => {
  let emailVerifyText = document.getElementById('email-verify') as HTMLElement;
  emailVerifyText.innerHTML = <string>getStorage('email') ?? '';
}

export const showVerifyEmailOriginalSection = (isOpen = true) => {
  const showSuccess = document.getElementById('verify-success-icon') as HTMLElement;
  const verifyEmailHeader = document.getElementById('verify-email-header') as HTMLElement;
  if (isOpen) {
    // show original verify email layout
    verifyEmailHeader.classList.remove('hidden');
    showSuccess.classList.add('hidden');
  } else {
    // show success icon and text review success on verify page
    verifyEmailHeader.classList.add('hidden');
    showSuccess.classList.remove('hidden');
  }
}

/**
 * check action todo after auth
 * */
export const actionCallbackAfterAuth = () => {
  const authCallBack = getStorage('auth_callback');
  if (authCallBack === 'submit-review-social-form') {
    // submit form review after sign-in
    window.dispatchEvent(new CustomEvent('set-data-submit-review', {
      detail: {'callback': 'sign-in'}
    }));
    // remove after done
    localStorage.removeItem('auth_callback');
  }
}

/** Alert after sign-in for auto sign-in */
export const alertSignIn = (data: any, msgSuccess = false) => {
  // show modal alert
  if (data?.success) {
    Toast.fire({
      icon: "success",
      title: msgSuccess ? i18nMain.t('loginSuccess') : data.message
    });
  } else {
    Toast.fire({
      icon: "error",
      title: i18nMain.t('failed', {
        defaultValue: 'Something went wrong. Please try again.'
      })
    });
  }
}

export const fetchSendMailVerifyEmail = async (email: string|unknown) => {
  let res = await fetch(`${appConfig.apiEndPoint}/verify-email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    body: JSON.stringify({
      email: email || getStorage('email'),
    })
  });
  return await res.json();
}

export const fetchIsVerifyEmail = async (email: string|unknown) => {
  let getEmail = email || getStorage('email');
  let res = await fetch(`${appConfig.apiEndPoint}/is-verify-email?email=${getEmail}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await res.json();
}

