import appConfig from '@/config'
import {getCsrfToken} from "@/utility/helper";
import {initInterPhone} from "@/modules/interPhone/inter-phone";
import '@/../css/pages/auth.css'
import {i18nMain} from "@/modules/translations/i18n.main";
import {contactFormSuccess, getMessage} from "@/modules/form/sign-up/helpers";
import {fetchContractUs} from "@/modules/form/fetch/contract-us";

// init inter phone
let itiPhone = initInterPhone("phone-contact");

interface attribute {
  name: string
  email: string
  phone: string
  subject: string | null
  message: string | null
  company_name: string | null
  line: string | null
  whatsapp: string | null
  website_url: string | null
  xml_feed_url: string | null
  interested_in_option: any
}

const defaultAttribute = <attribute>{
  name: "",
  email: "",
  phone: "",
  subject: "",
  line: "",
  whatsapp: "",
  company_name: null,
  message: "",
  website_url: "",
  xml_feed_url: "",
  interested_in_option: [],
}

/**
 * This is contact form
 * Use in contact page and register page
 * */
export default (formType = "contact-us") => ({
  attribute: <attribute>defaultAttribute,
  errors: <attribute>{},
  loading: false,
  message: "",
  async submit() {
    this.errors = <attribute>{};
    this.message = "";
    // validate phone number
    let isValid = itiPhone?.isValidNumber();
    if (!isValid) {
      this.message = i18nMain.t('errors.data-invalid');
      this.errors.phone = i18nMain.t(
        'errors.data-invalid-attribute', {
          attr: i18nMain.t('attributes.phone-number')
        });
      return;
    }
    this.loading = true
    let message = this.attribute.message;
    let phone = itiPhone?.getNumber();
    let subject = this.attribute.subject;
    if (formType === "contact-us-uae") {
      subject = `[UAE] Enquiry from ${this.attribute.name}`
    }
    /* If you have company name will add in message */
    if (this.attribute.company_name?.length) {
      message = getMessage(this.attribute);
      subject = "Register Form"
    }

    const res = await fetchContractUs({
      name: this.attribute.name,
      email: this.attribute.email,
      phone: phone,
      line: this.attribute.line,
      whatsapp: this.attribute.whatsapp,
      subject: subject,
      message: message,
    })

    const data = await res.json();
    this.loading = false;

    if (data?.success) {
      this.resetAttribute();
      contactFormSuccess(data);
    } else {
      this.message = data?.message
      this.errors = data?.errors
    }
  },
  resetAttribute() {
    this.attribute = <attribute>{
      name: "",
      email: "",
      phone: "",
      subject: "",
      line: "",
      whatsapp: "",
      message: "",
      company_name: null,
      website_url: "",
      xml_feed_url: "",
      interested_in_option: [],
    }
  },
  resetForm() {
    this.resetAttribute();
    this.errors = <attribute>{};
    this.message = "";
  }
});
