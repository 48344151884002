import {getLanguage, getStorage} from "@/utility/helper";
import {i18nMain} from "@/modules/translations/i18n.main";
import {fetchIsVerifyEmail, fetchSendMailVerifyEmail, showModalSignIn} from "@/pages/auth/helpers";

i18nMain.locale = getLanguage();

const dataResponse = {
  show: false,
  success: false,
  message: '',
  data: {},
  statusCode: 200,
  is_new_password: false,
}

export default (
  email = '' as string | unknown,
  showVerifyEmailDoneBtn = false as boolean | unknown
) => ({
  loading: false,
  verifyLoading: false,
  email: email,
  dataResponse: dataResponse,
  isSendEmail: false,
  async submitVerifyEmail() {
    this.dataResponse.show = false;
    this.loading = true;
    console.log(this.email)
    this.dataResponse = await fetchSendMailVerifyEmail(email);
    this.dataResponse.show = true;
    this.loading = false;
    if (showVerifyEmailDoneBtn) {
      this.isSendEmail = true;
    }
    /*let button = document.getElementById('text-resend-verify-email') as HTMLButtonElement;
    if (button) {
      button.innerText = i18nMain.t('resend-verify-email');
    }*/
  },
  async verifyEmailDoneBtn() {
    this.dataResponse.show = false;
    this.verifyLoading = true;
    this.dataResponse = await fetchIsVerifyEmail(this.email);
    this.dataResponse.show = true;
    this.verifyLoading = false;
    if (this.dataResponse?.success) {
      showModalSignIn(false);
      this.isSendEmail = false;
    }
  }
});