import {initInterPhone} from "@/modules/interPhone/inter-phone";
import '../../../../css/pages/auth.css'
import {i18nMain} from "@/modules/translations/i18n.main";
import {
  contactFormSuccess,
  fetchRegister,
  getMessage,
  setAuthTokenAfterRegister
} from "@/modules/form/sign-up/helpers";
import {facebookCompleteRegistration} from "@/modules/tracking/tracking.index";

// init inter phone
let itiPhone = initInterPhone("phone-agency-register");

interface attribute {
  first_name: string
  last_name: string
  email: string
  phone: string
  subject: string | null
  message: string | null
  company_name: string | null
  line: string | null
  whatsapp: string | null
  website_url: string | null
  xml_feed_url: string | null
  interested_in_option: any
}

const defaultAttribute = <attribute>{
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  line: "",
  whatsapp: "",
  company_name: null,
  website_url: "",
  xml_feed_url: "",
  interested_in_option: [],
}


/**
 * This is contact form
 * Use in contact page and register page
 * */
export default () => ({
  attribute: <attribute>defaultAttribute,
  errors: <attribute>{},
  loading: false,
  message: "",
  async submit() {
    this.errors = <attribute>{};
    this.message = "";
    // validate phone number
    let isValid = itiPhone?.isValidNumber();
    if (!isValid) {
      this.message = i18nMain.t('errors.data-invalid');
      this.errors.phone = i18nMain.t(
        'errors.data-invalid-attribute', {
          attr: i18nMain.t('attributes.phone-number')
        });
      return;
    }
    this.loading = true
    let message = getMessage(this.attribute);
    let phone = itiPhone?.getNumber();
    let subject = "Register Form";
    const data = await fetchRegister({
      first_name: this.attribute.first_name,
      last_name: this.attribute.last_name,
      company_name: this.attribute.company_name,
      website_url: this.attribute.website_url,
      xml_feed_url: this.attribute.xml_feed_url,
      interested_in_option: this.attribute.interested_in_option,
      email: this.attribute.email,
      phone: phone,
      line: this.attribute.line,
      whatsapp: this.attribute.whatsapp,
      subject: subject,
      message: message,
      role: 'manager',
      auto_sign_in: true,
    });
    //console.log(data)
    this.loading = false;
    if (data?.success) {
      this.resetAttribute();
      // update state for form
      contactFormSuccess(data);
      // tracking facebook
      facebookCompleteRegistration();
      // Set alert message for register form
      setAuthTokenAfterRegister(data, 'manager', 'static');
    } else {
      this.message = data?.message
      if (data?.errors) {
        this.errors = data?.errors
      }
    }
  },
  resetAttribute() {
    this.attribute = <attribute>{
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      line: "",
      whatsapp: "",
      message: "",
      company_name: "",
      website_url: "",
      xml_feed_url: "",
      interested_in_option: [],
    }
  },
  resetForm() {
    this.resetAttribute();
    this.errors = <attribute>{};
    this.message = "";
  }
});
