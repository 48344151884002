import appConfig from "@/config";
import {getCsrfToken, getLocale} from "@/utility/helper";
import {Alpine} from "@/alpineJS";
import {i18nMain} from "@/modules/translations/i18n.main";
import {setLogin} from "@/utility/authHelpers";
import {actionCallbackAfterAuth, alertSignIn, showModalSignIn} from "@/pages/auth/helpers";
import {ModalAlertType} from "@/modules/modals/modal";
import {AuthType} from "@/pages/auth/store-data";

export const fetchRegister = async (data: any) => {
  const res = await fetch(`${appConfig.apiEndPoint}/sign-up`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    body: JSON.stringify(data)
  })
  return await res.json();
}

export const contactFormSuccess = (response: any) => {
  if (response?.success) {
    /* Show modal alert */
    let dataStore = Alpine.store('modalAlert') as ModalAlertType;
    if (dataStore) {
      dataStore.toggle()
    }
    /* Show alert login page */
    let dataStoreAuth = Alpine.store('auth') as AuthType;
    if (dataStoreAuth) {
      dataStoreAuth.step = 3;
      dataStoreAuth.dataAlert = {
        show: true,
        message: response?.message ?? i18nMain.t('successData.send-contact-success'),
        type: 'success',
        className: 'alert-success',
      };
    }
  }
}


export const getMessage = (attribute: any) => {
  let message = "";
  let roleElement = document.querySelector('input[name="selectedRole"]:checked') as HTMLInputElement;
  if (roleElement) {
    message += `<b>Role:</b> ${roleElement.value}`;
  }
  Object.keys(attribute).forEach((key) => {
    if (key === 'line') {
      message += attribute[key]?.length ? `<b>Line:</b> ${attribute[key]},` : "";
    } else if (key === 'whatsapp') {
      message += attribute[key]?.length ? `,<b>Whatsapp:</b> ${attribute[key]},` : "";
    } else if (key === 'company_name') {
      message += attribute[key]?.length ? `<b>Company Name:</b> ${attribute[key]}` : "";
    } else if (key === 'message') {
      message += attribute[key]?.length ? `,<b>Message:</b> ${attribute[key]}` : "";
    } else if (key === 'website_url') {
      message += attribute[key]?.length ? `,<b>Website URL:</b> ${attribute[key]}` : "";
    } else if (key === 'xml_feed_url') {
      message += attribute[key]?.length ? `,<b>XML Feed URL:</b> ${attribute[key]}` : "";
    } else if (key === 'interested_in_option') {
      let interestedIn = attribute[key].join(',');
      message += attribute[key]?.length ? `,<b>I am interested in:</b>, ${interestedIn.replaceAll('-', ' ')}` : "";
    }
  })
  return message;
}


export const setAuthTokenAfterRegister = (
  data = null as any,
  roleName: string,
  page: string | unknown
) => {
  // register static page => role seeker auto sign-in => alert text
  // register modal auto sign-in => alert toast
  if (page === 'static') {
    if (roleName === 'property-seeker' && data?.auth_token) {
      // set auth token
      setLogin(data.auth_token, data.email);
      // redirect to dashboard
      const locale = getLocale();
      window.location.href = appConfig.domain + "/my-account?locale=" + locale;
    } else if (['home-owner', 'freelance-agent', 'manager'].includes(roleName) && data?.auth_token) {
      // set auth token
      setLogin(data.auth_token, data.email);
      // redirect to referrer url
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      }
    }
    // Set alert message
    setMsgSuccessGlobalState(data);
  } else {
    // page === 'modal' => always auto sign-in => response auth_token
    setLogin(data.auth_token, data.email);
    showModalSignIn(false);
    alertSignIn(data, true);
    // check Todo action after auth
    actionCallbackAfterAuth();
  }
}

/* For static register page */
const setMsgSuccessGlobalState = (data: any) => {
  let dataStore = Alpine.store('auth') as AuthType;
  dataStore.step = 3;
  dataStore.dataAlert = {
    show: true,
    message: data?.message ?? 'Your account has been successful created.',
    type: 'success',
    className: 'alert-success'
  };
}


/**
 * Set value input email sign form
 * */
export const setEmail = (email: string) => {
  let emailEle = document.getElementById('signin-email') as HTMLInputElement;
  if (emailEle) {
    emailEle.value = email;
  }
}

